<template>
  <div>
  </div>
</template>

<script>
import check from "../components/check.vue";
import Canael from "../components/canael.vue";
import DingGai from "../components/dingGai.vue";
import Routine from "../components/routine.vue";
import unusual from "../components/unusual.vue";
import copy from "../components/copy.vue";
import Cookies from "js-cookie";
import phonebar from "jssip-emicnet/dist/phonebar";
import part from "../components/part.vue";
import match from "../components/match.vue";
import * as api from "../api/index";
import { sendSock, createWebSocket, closeSock } from "../api/websockwork.js";
import * as ola from "../api/olawebsock.js";
import * as vt from "../api/verto.js";
import "../assets/iconfont/iconfont.css";
// Symbol
import "../assets/iconfont/iconfont.js";

export default {
  beforeRouteEnter(to,from,next){
    next(vm=>{
      vm.$router.replace('/');
    })
  }
 
};
</script>

<style lang="less" scoped>
.zhuang {
  width: 80px;
  height: 32px;
  font-size: 14px;
  text-align: center;
  float: left;
  line-height: 32px;
  color: #fff;
  margin-top: 3px;
  box-sizing: border-box;
}
.jinXing {
  // width: 1170px;
  height: 500px;
  padding: 0 30px;
  box-sizing: border-box;
}

.jinXing /deep/ .el-table th.el-table__cell.is-leaf {
  background-color: #f5f5f5;
  color: #000;
  height: 30px;
}

.jinXing /deep/ .el-table__inner-wrapper {
  width: 100%;
  z-index: 0;
}

.jinXing /deep/ .el-table-fixed-column--right:last-of-type {
  width: 310px;
  display: flex;
  align-items: center;
  height: 50px !important;
}
.jinXing
  /deep/
  .el-table--enable-row-transition
  .el-table__body
  td.el-table__cell {
  height: 70px !important;
}

.jinXing /deep/ .el-table__row {
  height: 70px !important;
  box-sizing: border-box;
}
.jinXing /deep/ .el-table__cell {
  padding: 0;
}
.jinXing /deep/ .el-button--small {
  width: 60px !important;
  margin-left: 10px;
}
.jinXing /deep/ .el-button + .el-button {
  margin: 0;
  margin-left: 10px;
}
.jinXing /deep/ .is-vertical {
  width: 15px !important;
}
.jinXing /deep/ .is-horizontal {
  height: 15px !important;
}

.taitu {
  display: flex;
  width: 130px;
  cursor: pointer;
}

table {
  width: 1170px;
  padding-left: 30px;
  font-size: 14px;
}
thead {
  height: 30px;
  background-color: #f8fafc;
}
.tai {
  width: 100px;
  padding-left: 30px;
}
.num {
  width: 150px;
  padding-right: 40px;
}
.carDian {
  width: 250px;
  padding-right: 120px;
}
.zuo {
  padding-right: 65px;
}
// 工作台列表新
.tdNum {
  padding-right: 30px;
}

th {
  // width: 190px;
  box-sizing: border-box;
  text-align: left;
}
tr {
  height: 70px !important;
}

tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  background-color: #ffffff !important;
}
.bg_color2 {
  background-color: #fafafa !important;
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.apply {
  width: 34px;
  height: 34px;
  position: relative;
  top: -37px;
  right: -420px;
  cursor: pointer;
}
.box-main {
  width: 1730px;
  height: 840px;
  margin-left: 190px;
  background-color: #f7f9fb;
}
.middle {
  width: 1670px;
  // width: 1315px;
  height: 840px;
  margin-left: 30px;
  float: left;
  //   background-color: skyblue;
}
.newmiddle {
  width: 1670px;
  height: 100%;
  float: left;
}
.newjinxing {
  height: 700px !important;
}
.newmian {
  height: 95% !important;
}
.right-box {
  float: right;
  width: 350px;
  height: 840px;
  margin-left: 30px;
  //   background-color: darkgrey;
}
// 进度
.plan-ul {
  display: flex;
  list-style: none;
  height: 107px;
  box-sizing: border-box;
  margin-top: 20px;
}
.newjindu {
  display: flex;
  width: 100%;
  height: 180px;
  // background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
  justify-content: space-between;
}
.newjinduleft {
  width: 1316px;
  height: 100%;
  border-radius: 8px;
  background-color: #fff;
  padding: 15px 30px;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
.newjinduright {
  width: 320px;
  height: 100%;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px 30px;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
.allnav {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.allimg {
  width: 26px;
  height: 26px;
  margin-right: 15px;
}
.allmain {
  width: 100%;
  height: 105px;
  border-radius: 8px;
  background-image: url("../assets/img/index/allbei.png");
  background-size: 100% 100%;
  margin-top: 10px;
  padding: 14px 30px;
  box-sizing: border-box;
}
.allmaintit,
.allmainbottom {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  color: #fff;
}
.allmainbottom {
  margin-top: 7px;
}
.allmaintit {
  margin-right: 20px;
}
.allnum {
  font-size: 32px;
  font-weight: bold;
  margin: 0 10px;
}
.allcheng {
  font-size: 18px;
  font-weight: bold;
  margin: 0 10px;
}
.newleft {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newtit {
  font-size: 14px;
  font-weight: 500;
}
.newzi {
  width: 52px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  background-color: #f7f8fa;
  border-radius: 8px;
  color: #727e96;
  font-size: 14px;
  margin: 0 10px;
  cursor: pointer;
}
.newzi:hover {
  background-color: #edf2ff;
  color: #2c68ff;
}
.newxuanzi {
  width: 52px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 8px;
  background-color: #edf2ff;
  color: #2c68ff;
  font-size: 14px;
  margin: 0 10px;
  cursor: pointer;
}
.newxuan {
  // border: 1px solid #e2e2e5;
  height: 100%;
  display: flex;
  align-items: center;
}
.newleftmain {
  width: 100%;
  height: 108px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newjishi {
  width: 260px;
  height: 100%;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 1px solid #edeff3;
}
.jishinav {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newjishitit {
  font-size: 14px;
  font-weight: 400;
}
.newnum,
.newnum2 {
  font-size: 14px;
  font-weight: 700;
}
.newnum {
  color: #1ad092;
}
.newnum2 {
  color: #ff202a;
}
.shengimg {
  width: 20px;
  height: 20px;
}
.jishinum {
  display: flex;
  align-items: center;
}
.jishimain {
  display: flex;
  align-items: flex-end;
  margin-top: 5px;
}
.jishimainimg {
  width: 40px;
  height: 40px;
}
.dannum {
  margin-left: 15px;
  font-size: 12px;
  color: #b6b6b6;
}
.danshu {
  font-size: 28px;
  color: #333333;
}
.jishibottom {
  width: 100%;
  height: 18px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
  color: #666666;
}
.completenum {
  color: #05bb7d;
  font-weight: 700;
}
.completeno {
  color: #fa1515;
  font-weight: 700;
}

.plan {
  float: left;
  width: 210px;
  height: 107px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.16);
  opacity: 1;
  padding: 20px;
  box-sizing: border-box;
  margin-right: 30px;
  border-radius: 8px;
  background-color: #fff;
}
.jindu {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.xinNum {
  display: flex;
}
.style {
  background: linear-gradient(180deg, #e2efff 0%, #ffffff 100%);
}
.five {
  margin: 0;
}
.p3 {
  width: 170px;
  height: 8px;
  background: #eef1f3;
  opacity: 1;
  border-radius: 30px;
  margin: 0;
  // margin-top: 25px;
}
.shuNum {
  margin-bottom: 10px;
}
.taibox {
  display: flex;
  align-items: center;
}
.dingnum {
  font-size: 24px;
}
.s1 {
  display: inline-block;
  width: 150px;
  height: 8px;
  background: linear-gradient(270deg, #2c68ff 0%, #4a9fff 100%);
  opacity: 1;
  border-radius: 20px;
  margin-bottom: 9px;
}
.main {
  // width: 1170px;
  width: 100%;
  height: 610px;
  // height: 675px;
  // background-color: orange;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.16);
  opacity: 1;
  border-radius: 8px;
  position: relative;
}
.main-nav {
  width: 100%;
  // height: 105px;
  overflow: hidden;
  // background-color: skyblue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 30px;
  box-sizing: border-box;
  // background-color: pink;
}
.newhead {
  width: 100%;
  // height: 35px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 10px;
}
.newheadbom {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
  margin-top: 10px;
}
.newheadbom /deep/ .el-form-item--default {
  margin-right: 20px;
}
.newheadbom /deep/ .el-form {
  height: 30px !important;
}
.newsou {
  display: flex;
  align-items: center;
}
.newtime {
  display: flex;
}
.newtime /deep/ .el-input--prefix .el-input__inner {
  margin-right: 10px;
}
.newbtn,
.newbtn2 {
  // width: 60px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 20px;
}
.newbtn {
  background-color: #2c68ff;
}
.newbtn2 {
  background-color: #bcbcbc;
}
.dabei {
  background-color: #bcbcbc;
  cursor: pointer;
}
.newzhi {
  width: 15px;
  height: 15px;
  //margin-right: 5px;
}
.reset1,
.reset2 {
  font-size: 12px;
  border: 0;
  cursor: pointer;
  color: #fff;
  outline: 0;
}
.reset1 {
  background-color: #2c68ff;
}
.reset2 {
  background-color: #bcbcbc;
}
// .reset:hover {
//   color: #40bcff;
//   border: 1px solid #40bcff;
// }
.state li a {
  width: 72px;
  height: 72px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: #333333;
  opacity: 1;
  float: left;
  cursor: pointer;
  text-decoration: none;
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f8fa;
}
.state li a:hover {
  color: #2c68ff;
}
.state {
  display: flex;
  align-items: center;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
.newtabnum {
  font-size: 20px;
  font-weight: bold;
}
.newactive {
  font-size: 20px;
  font-weight: bold;
  color: #b5b5be;
}
.active {
  color: #2c68ff !important;
  background-color: #edf2ff !important;
  padding: 4px 8px;
  box-sizing: border-box;
  border-radius: 4px;
}
.activenum {
  color: #2c68ff !important;
}
.newheadnum {
  color: #9ea3b9;
}

.time-box {
  // width: 400px;
  height: 40px;
  // margin-left: 697px;
  margin-top: 15px;
  // background-color: greenyellow;
  display: flex;
  align-items: center;
}
.time {
  float: left;
  // width: 160px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  padding: 0 10px;
  opacity: 1;
  border-radius: 2px;
  text-align: center;
  line-height: 40px;
  // margin-right: 20px;
  box-sizing: border-box;
  // background-color: orangered;
}
.seek {
  float: left;
  // width: 200px;
  // height: 40px;
  background: #ffffff;
  // border: 1px solid #dadfe6;
  opacity: 1;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  display: flex;
}
.sekint {
  float: left;
  width: 156px;
  height: 37px;
  padding: 15px;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid #dadfe6;
  border-left: 0;
}
.glass {
  // float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  margin-top: -1px;
  background: #f7f9fb;
  // border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: 2px;
  right: 1px;
  cursor: pointer;
}
.icon {
  width: 14px;
  height: 14px;
  // margin-left: 13px;
  // margin-top: 13px;
  cursor: pointer;
}
.time i {
  font-style: normal;
}
.time i:hover {
  color: #2c68ff;
}
.day,
.week,
.xing {
  font-size: 14px;
  cursor: pointer;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  // margin-right: 10px;
  color: #727e96;
  opacity: 1;
}
.hue {
  color: #2c68ff;
}
.tiao {
  display: inline-block;
  width: 0px;
  height: 14px;
  // margin-right: 10px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
  margin: 0 5px;
}
.tiao1 {
  display: inline-block;
  width: 0px;
  height: 14px;
  margin-right: 10px;
  margin-left: 10px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
}

// 进行中
.march {
  list-style: none;
  height: 450px;
  overflow: hidden;
}
.march::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.march-box {
  float: left;
}
.handle {
  float: right;
  margin-right: 34px;
  // margin-top: 30px;
  line-height: 70px;
}
.handle i {
  font-style: normal;
}
.march li {
  cursor: pointer;
  height: 70px;
  border-bottom: 1px solid #f7f9fb;
  box-sizing: border-box;
}
.march li:hover {
  background-color: #f9fafe !important;
}
.li-img {
  float: left;
  width: 40px;
  height: 40px;
  // margin-top: 23px;
  // padding-left: 30px;
}
.tatai {
  width: 80px;
  height: 32px;
  font-size: 14px;
  text-align: center;
  float: left;
  line-height: 32px;
  margin-top: 18px;
  color: white;
}
.p1-span,
.p1-span1 {
  width: 537px;
  height: 21px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  opacity: 1;
}
.p1-span {
  color: #2c68ff;
}
.p1-span1 {
  color: #2a3346;
}
.p1-span2 {
  width: 79px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #727e96;
  opacity: 1;
}
.p2 {
  width: 337px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #727e96;
  opacity: 1;
  margin-left: 10px;
  margin-top: -15px;
}
i {
  font-style: normal;
}
.abolish,
.refromed {
  width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #2c68ff;
  opacity: 1;
  cursor: pointer;
}
// .abolish:hover{
//   color: #0212be;
// }
// .refromed:hover{
//   color: red;
// }
.jinXing-sorter {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  position: absolute;
  right: 0;
  bottom: -10px;
}
.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  margin-left: 5px;
}
.carcall {
  display: flex;
  align-items: center;
}

.take,
.take1,
.take2,
.take3 {
  display: inline-block;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  box-sizing: border-box;
  background: #e8f3fe;
  opacity: 1;
  border-radius: 2px;
  color: #2c68ff;
  vertical-align: top;
  padding: 0 5px;
}

.switch {
  display: flex;
}
.pingtai {
  width: 130px;
  font-size: 14px;
  color: #727e96;
}

.jishistyle,
.shunlustyle,
.yuyuestyle,
.jishistyle2,
.shunlustyle2,
.yuyuestyle2 {
  display: inline-block;
  // width: 60px;
  height: 25px;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  border-radius: 4px;
  vertical-align: top;
  padding: 0 5px;
  cursor: pointer;
}
.take1 {
  color: #2c68ff;
  background: #e8f3fe;
}
.take2 {
  color: #27cc8b;
  background: #eefbf4;
}
.jishistyle,
.yuyuestyle,
.shunlustyle {
  color: #666;
  background-color: #ededed;
  margin-right: 10px;
}
.jishistyle2,
.yuyuestyle2,
.shunlustyle2 {
  color: #fff;
  background-color: #2c68ff;
  margin-right: 10px;
}

.take3 {
  color: #fff6e9;
  background: #ff9100;
}

.danbox {
  display: flex;
  // align-items: center;
  flex-direction: column;
}
.danboxleft {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.seek /deep/ .el-select .el-input__inner {
  width: 100px;
  border-radius: 0;
  margin-left: 20px;
}
.newsou /deep/ .el-select .el-input__inner {
  width: 100px;
  border-radius: 0;
  margin-left: 20px;
}
.newsou /deep/ .el-select .el-input .el-select__caret.el-icon {
  z-index: 0;
}

.yuyuetime {
  color: #27cc8b;
  cursor: pointer;
}

.maketit {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #6d6262;
  padding: 2px 5px;
  box-sizing: border-box;
  color: #fff;
}
.paixustyle {
  cursor: pointer;
}

.splitimg {
  width: 16px;
  height: 16px;
  margin-top: 8px;
  margin-left: 5px;
}

.jinXing /deep/ .el-table td.el-table__cell div {
  //display: flex;
  align-items: center;
}

.immediate,
.immediate1,
.immediate2,
.immediate3 {
  width: 220px;
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}
.immediate4 {
  width: 220px;
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}
.immediate {
  background-color: #4076ff;
}
.immediate1 {
  background-color: #29cc8c;
}
.immediate2 {
  background-color: #ff950a;
}
.immediate3 {
  background-color: #727e96;
}
.immediate4 {
  background-color: #ff525c;
}
.immediate /deep/ .el-progress-bar__outer {
  background-color: #8dadff;
}
.immediate1 /deep/ .el-progress-bar__outer {
  background-color: #7fe1ba;
}
.immediate2 /deep/ .el-progress-bar__outer {
  background-color: #ffc06c;
}
.immediate3 /deep/ .el-progress-bar__outer {
  background-color: #abb2c0;
}
.immediate4 /deep/ .el-progress-bar__outer {
  background-color: #ff979d;
}
.immediatetxt,
.immediatetxt1,
.immediatetxt2,
.immediatetxt3,
.immediatetxt4 {
  width: 74px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 8px 0px 20px;
  font-size: 14px;
}
.immediatetxt {
  background-color: #edf2ff;
  color: #4076ff;
}
.immediatetxt1 {
  background-color: #e8faf3;
  color: #29cc8c;
}
.immediatetxt2 {
  background-color: #faf3e9;
  color: #ff950a;
}
.immediatetxt3 {
  background-color: #edf1f5;
  color: #727e96;
}
.immediatetxt4 {
  background-color: #ffedee;
  color: #ff525c;
}
.immediatenum {
  font-size: 36px;
  color: #fff;
  display: flex;
  align-items: center;
}
.immediateadd {
  font-size: 14px;
  margin-left: 10px;
}
.immediatexian {
  width: 180px;
  height: 4px;
  border-radius: 2px;
  background-color: #e0e0e0;
}
.immediatetit {
  font-size: 12px;
  color: #fff;
}

.zongnum {
  // font-style: normal;
  // width: 44px;
  // height: 24px;
  text-align: center;
  // line-height: 24px;
  color: #fff;
  font-weight: bold;
  background-color: #ff505a;
  border-radius: 12px;
  padding: 2px 12px;
  box-sizing: border-box;
}
.allnavleft {
  display: flex;
  align-items: center;
}
.totalorder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.totalorder:after {
  content: "";
  flex: auto;
}
.totalorderblock {
  width: 46px;
  height: 60px;
  font-size: 20px;
  color: #727e96;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 15px;
  box-sizing: border-box;
  cursor: pointer;
}
.totalordertit {
  font-size: 14px;
}
.totalordernum {
  color: #b5b5be;
}

.totalorderactive {
  color: #2a3346;
}
.beimg {
  // width: 120px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
}
</style>

<style>
.partcengji {
  z-index: 30 !important;
}
.checkcengji {
  z-index: 20 !important;
}
</style>
